const rootDomain = 'everyspace.me';
const publicDomain = `app.${rootDomain}`;
const internalDomain = `everyspace.${rootDomain}`;
const assetsBaseUrl = '/assets';

export const environment = {
  production: true,
  environment: 'stg',
  rootDomain,
  host: publicDomain,
  baseUrl: `https://${publicDomain}`,
  internalBaseUrl: `https://${internalDomain}`,
  assetsBaseUrl,
  firebase: {
    apiKey: 'AIzaSyCW4lWk5yU0mUiqOOKMNcPKg_IP7bdxOH4',
    authDomain: 'everyspace-stg.firebaseapp.com',
    projectId: 'everyspace-stg',
    storageBucket: 'everyspace-stg.appspot.com',
    messagingSenderId: '566139475619',
    appId: '1:566139475619:web:83fd9a52f1d9ddf3605116',
  },
  googleClientId: '566139475619-6trlf5uvsc4c0pnvag4vnjfk8um22lbo.apps.googleusercontent.com',
  googleAnalyticsTrackingId: '',
  useEmulators: false,
  slack: {
    oauthURL: 'https://slack.com/oauth/v2/authorize',
    clientID: '1023424317844.1219741923664',
    scopes: 'incoming-webhook,channels:read,groups:read,users:read,users:read.email,chat:write,chat:write.customize',
    userScopes: 'channels:write,groups:write',
    requiredScopes: [
      'incoming-webhook',
      'channels:read',
      'groups:read',
      'users:read',
      'users:read.email',
      'chat:write',
      'chat:write.customize',
    ],
    requiredUserScopes: ['channels:write', 'groups:write'],
  },
  zoom: {
    clientID: 'VLS88gUTRAeER2j0FsIUVw',
  },
  loomApiKey: null,
  mixPanelToken: null,
  sentryDsn: null,
  twilioEnabled: false,
};
